.venue-filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
}

.venue-filter {
    width: 100%;
    position: relative;
}

.venue-filter select {
    width: 100%;
    padding: 8px;
    padding-right: 30px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--cardBackground-color);
    color: var(--text-color1);
    text-align: left;
}

.venue-filter::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0d7"; /* fa-caret-down */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color1);
    pointer-events: none;
    font-size: 16px; /* Adjust size as needed */
}

.venue-filter select:focus {
    border-color: var(--highlight-color);
    outline: none;
}

.venue-filter select option {
    padding: 8px 12px;
    text-align: left;
}

.venue-filter select option:disabled {
    color: var(--text-color3);
    font-weight: bold;
    padding: 5px 0;
}

/* Style for the divider */
.venue-filter select option[value=""] {
    font-weight: bold;
    color: var(--text-color2);
}

/* Style for "Login for Saved Shows" */
.venue-filter select option[value="saved"]:disabled {
    color: var(--text-color3);
    font-style: italic;
}

.clear-search-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.clear-search-button {
    padding: 8px 12px;
    background-color: var(--secondary-color);
    color: var(--text-color2);
    width: 100%;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.clear-search-button:hover {
    background-color: var(--highlight-color);
}

.venue-filter select.disabled {
    background-color: var(--cardBackground-color);
    color: var(--text-color3);
    cursor: not-allowed;
}

.venue-filter select.disabled:hover {
    border-color: var(--border-color);
}
