body, button, input, select, textarea {
    font-family: 'Inter', sans-serif;
}

.search-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dim-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    border: none;
}

.search-container.desktop {
    background-color: transparent;
    justify-content: flex-end;
    align-items: flex-start;
    pointer-events: none;
}

.search-popup {
    background-color: var(--cardBackground-color);
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
    max-height: 90vh;
    overflow-y: auto;
    pointer-events: auto;
    border: 1px solid var(--border-color);
}

.search-container.desktop .search-popup {
    height: 100vh;
    border-radius: 12px 0 0 0;
    transform: translateX(100%);
    animation: slideIn 0.3s forwards;
    padding-top: 80px;
    border-left: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);;
    border-bottom: 1px solid var(--border-color);;
    border-right: none;
}

@keyframes slideIn {
    to { transform: translateX(0); }
}

.search-header {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color1);
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 300px;
    right: 15px;
    background: none;
    border: none;
    font-size: 100px;
    cursor: pointer;
    color: pink;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: var(--text-color2);
}

.search-input-container {
    position: relative;
    width: 100%;
    padding: 0px 0px 15px 0px;
}

.search-icon, .calendar-icon {
    position: absolute;
    left: 12px;
    top: 38%;
    transform: translateY(-50%);
    color: var(--primary-color);
}

.calendar-icon {
    z-index: 2;
    cursor: pointer;
}

.search-input, .date-input {
    width: 100%;
    padding: 12px 12px 12px 40px;
    border: .1px solid var(--text-color1);
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
}

.search-input:focus, .date-input:focus {
    outline: none;
    border-color: var(--highlight-color);
}

.date-input {
    cursor: pointer;
}

.date-pickers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.date-picker {
    width: 48%;
    position: relative;
}

.search-button {
    background-color: var(--highlight-color);
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background-color: var(--highlight-color);
}

.search-button svg {
    margin-right: 8px;
}

.search-popup .venue-filter {
    margin-top: 16px;
    width: 100%;
}

.search-popup .venue-filter select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: var(--cardBackground-color);
}

@media (max-width: 768px) {
    .search-container.desktop .search-popup {
        width: 100%;
        max-width: none;
        padding-top: 60px;
    }

    .search-popup {
        width: 95%;
        max-width: 95%;
        height: auto;
    }

    .date-pickers {
        flex-direction: column;
    }

    .date-picker {
        width: 100%;
        margin-bottom: 15px;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        width: 100%;
    }

    .react-datepicker-popper {
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        z-index: 1001 !important;
    }

    .react-datepicker {
        width: 90vw;
        max-width: 350px;
        font-size: 1rem;
        border-radius: 8px;
        border: 1px solid var(--primary-color);
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker__day-name, .react-datepicker__day {
        width: 2rem;
        line-height: 2rem;
        margin: 0.2rem;
    }

    .react-datepicker__header {
        border-bottom: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding-top: 8px;
    }

    .react-datepicker__current-month,
    .react-datepicker__day-name {
        color: rgb(0, 0, 0);
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
        background-color: var(--primary-color);
        color: white;
    }

    .react-datepicker__day:hover {
        background-color: var(--highlight-color);
    }

    body.search-open {
        overflow: hidden;
    }
}

@media (min-width: 768px) {
    body.search-open {
        overflow: auto;
    }
}
