.event-card.featured {
    max-width: 400px;
    margin: 3px auto;
    cursor: pointer;
}

.badges-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    margin-left: auto;
}

.featured-badge {
    background-color: var(--tertiary-color);
    color: var(--text-color2);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: bold;
}

.featured-image-container {
    width: 100%;
    margin-top: 12px;
    border-radius: 4px;
    overflow: hidden;
}

.featured-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

@media (max-width: 600px) {
    .event-card.featured {
        width: calc(100% - 20px);
    }
}