:root {
  --primary-color: #000000;
  --secondary-color: #0c6268;
  --highlight-color: #199ba4;
  --tertiary-color: #e21d6f;
  --background-color: #00121e;
  --cardBackground-color: #ffffff;
  --border-color: #e0e0e0;
  --text-color1: #000000;
  --text-color2: #ffffff;
  --text-color3: #666666;
  --event-date-color: #555;
  --event-day-color: #777;
  --event-name-color: #333;
  --event-venue-color: #666;
  --gradient-color1: #004C59;
  --gradient-color2: #671B46;
  --dim-color: #000000aa;
}

.always-light {
  --primary-color: #000000;
  --secondary-color: #0c6268;
  --highlight-color: #199ba4;
  --tertiary-color: #e21d6f;
  --cardBackground-color: #ffffff;
  --text-color1: #000000;
  --text-color2: #ffffff;
  --text-color3: #666666;
  /* Add any other light mode specific variables */
}


[data-theme='dark'] {
  --primary-color: #000000;
  --secondary-color: #1a9ba6;
  --highlight-color: #25c4cf;
  --tertiary-color: #ff4d8f;
  --background-color: #00121e;
  --cardBackground-color: #1e1e1e;
  --border-color: #5b5b5b;
  --text-color1: #ffffff;
  --text-color2: #ffffff;
  --text-color3: #bbbbbb;
  --event-date-color: #aaa;
  --event-day-color: #999;
  --event-name-color: #dedede;
  --event-venue-color: #bbbbbb;
  /* Keep the gradient colors the same for consistency */
  --gradient-color1: #004C59;
  --gradient-color2: #671B46;
  --dim-color: #00000057;
}

.app-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background-image: url('/src/assets/ggglitch.svg'); 
  background-color: var(--background-color);
  background-position: center top;
  background-size: 100% auto; /* This makes the image width 100% of the container */
  background-repeat: repeat-y; /* This makes the image repeat vertically */
  background-size: 1440px auto;
  margin-top: .5px; /*0 px makes the svg looks strange */

}

.content-wrapper {
  margin-top: 25px;
  /* padding: 20px; */
}

@media (max-width: 767px) {
  .app-main {
    /* Use a linear-gradient that will be the same in both light and dark modes */
    background-image: linear-gradient(135deg, #004C59 0%, #00121e 20%, #671B46 70%);
    background-position: center top;
    background-size: 100% auto;
  }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}



