.card-button {
    width: 100%;
    padding: 10px;
    background-color: var(--secondary-color) ;
    color: var(--text-color2);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    display: block; /* Changed from inline-block to block */
    box-sizing: border-box; /* Ensure padding is included in width */
}

.card-button:hover {
    background-color: var(--highlight-color);
;
}