.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 10px;
    background-color: var(--primary-color);
    color: var(--text-color2);
    min-height: 40px;
}

.footer.desktop {
    padding: 10px 0;
    min-height: 80px;
}

.footer-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.footer.desktop .footer-content {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 20px;
}

.footer-logo {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0px;
}

.footer.desktop .footer-logo {
    margin-bottom: 0;
}

.footer-logo-img {
    width: 150px;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s;
}

.footer-logo-img:hover {
    transform: scale(1.05);
}

.social-links {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 0px;
}

.footer.desktop .social-links {
    margin-top: 0;
}

.social-links a {
    color: var(--text-color2);
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: var(--highlight-color);
}

.footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.footer.desktop .footer-links {
    align-items: flex-end;
    margin-top: 0;
}

.footer-links a {
    text-decoration: none;
    color: var(--text-color2);
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: var(--highlight-color);
}

.footer-links a:active {
    color: var(--highlight-color);
}