.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dim-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-container {
  max-width: 400px;
  width: 100%;
  background-color: var(--cardBackground-color);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border-width: 5px;
  border-color: var(pink);
  position: relative;
  border: 1px solid var(--event-venue-color);
}

.login-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: var(--text-color1);
}

.login-loading-text {
  text-align: center;
  font-size: 18px;
  color: var(--text-color1);
}

.login-title {
  color: var(--text-color1);
  text-align: center;
  margin-bottom: 20px;
}
