.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-controls.top {
    margin-top: 0;
    margin-bottom: 8px;
}

.pagination-controls.bottom {
    margin-top: 8px;
    margin-bottom: 0;
}

.pagination-controls button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: var(--cardBackground-color);
    border: 1px solid var(--border-color);
    color: var(--text-color1);
    border-radius: 4px;
    cursor: pointer;
}

.pagination-controls button:hover:not(:disabled) {
    background-color: var(--highlight-color);
    border: 1px solid var(--highlight-color);
}

.pagination-controls button:active:not(:disabled) {
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
}

.pagination-controls button:disabled {
    cursor: not-allowed;
    color: #bebebe;
    background-color: #8b8b8b;
    border: 1px solid #000000;
}

.pagination-controls span {
    margin: 0 10px;
    font-size: 16px;
    color: white;
}

@media (max-width: 600px) {
    .pagination-controls {
        width: 100%;
        justify-content: center;
        padding: 0 5px;
        gap: 10px;
    }

    .pagination-controls button {
        margin: 0;
        font-size: .9rem;
        padding: 4px 6px;
        min-width: 32px;
    }

    .pagination-controls span {
        font-size: 0.9rem;
        margin: 0 4px;
    }
}
