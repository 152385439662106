.event-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    flex-grow: 1;
    min-height: 100vh; /* Ensure the container takes at least the full viewport height */
}


@media (max-width: 600px) {
    /* this is for the event list*/
    .event-list-container { 
        padding: 10px 0; /* Remove horizontal padding */
    } 
    
}
/* body, html {
    height: 100%;
    margin: 0;
    padding: 0;
} */

/* #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
} */

/* Add this to your existing EventList.css */
/* .clear-search-button {
    display: block;
    width: 100%;
    max-width: 300px;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.clear-search-button:hover {
    background-color: var(--highlight-color);
} */
