@import url('https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&display=swap');

/* .about-page {
    padding: 0px;
    text-align: center;
} */

/* .about-page h1 {
    font-size: 2em;
    margin-bottom: 10px;
} */

/* .about-page p {
    font-size: 1.2em;
    color: #ff0000;
} */

.about-container {
    max-width: 700px; 
    margin: 20px auto 20px; /* Increased top margin */
    padding: 1px;
    font-family: 'Inter', sans-serif;
    line-height: 1.5;
    color: var(--text-color1);
    background-color: var(--cardBackground-color);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-header {
    text-align: center;
    margin-bottom: 1px;
}

.about-header h1 {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0px;
    font-family: 'Kameron', sans-serif;
    font-weight: 200;
    font-size: 5em;
    margin-bottom: 1px;
    margin-top: 20px;

    /* color: var(white); */
}

.about-subtitle {
    font-size: 0.5em;
}

.about-content {
    max-width: 600px; 
    margin: 0 auto;
}

.about-section {
    margin-bottom: 20px;
}

.about-section h2 {
    font-size: 1.8em;
    /* color: var(white); */
    margin-bottom: 15px;
    font-family: 'Kameron', sans-serif;
}

/* .about-section p {
    margin-bottom: 15px;
} */

@media (max-width: 768px) {
    /* .about-container {
        padding: 15px;
    } */

    .about-container {
        margin: 20px auto auto auto;
        padding: 20px;
        padding-top: 10px;
    }

    .about-header h1 {
        font-size: 3em;
    }

    .about-section h2 {
        font-size: 1.5em;
    }
}
