/* UserPanel.css */

/* Apply Inter font to all text */
body, button, input, select, textarea {
    font-family: 'Inter', sans-serif;
}

.user-panel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center; /* Center the popup */
    align-items: center; /* Center the popup */
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    overflow: hidden; /* Prevent scrolling on the overlay */
    
}

.user-panel-content {
    background-color: var(--cardBackground-color);
    width: 90%;
    max-width: 400px;
    height: auto;
    padding: 1.5rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: flex-start;
    position: relative;
    border-radius: 12px;
    max-height: 90vh;
    /* border: 5px solid var(--border-color); */
}

.user-panel-header {
    font-size: 1.3rem; /* Slightly reduced font size */
    font-weight: 600;
    color: var(--text-color1);
    width: 100%;
    text-align: left; /* Left justify header */
    margin-bottom: 0; /* Remove bottom margin */
    padding-bottom: 0; /* Remove bottom padding */
    line-height: 1.2; /* Adjust line height to bring text closer */
}

.close-button {
    position: absolute; /* Position it absolutely within the user-panel-content */
    top: 1rem; /* Adjust as needed */
    right: 1rem; /* Adjust as needed */
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--text-color1);
    transition: color 0.3s ease;
}

.close-button:hover {
    color: var(--highlight-color);
}

.user-email {
    font-size: 1rem; /* Reduced font size */
    color: var(--text-color1); /* Match the color scheme */
    margin: 0 0 1rem 0; /* Remove top margin, add bottom margin */
    text-align: left; /* Left justify the email */
    width: 100%; /* Ensure it takes full width */
    line-height: 1.2; /* Adjust line height to bring text closer */
    padding-bottom: 0.5rem; /* Add padding to separate from links */
    border-bottom: 2px solid var(--text-color1); /* Add a border to separate from links */
}


.user-panel-link {
    color: var(--text-color1);
    text-decoration: none;
    font-size: 0.9rem; /* Slightly reduced font size */
    margin-bottom: 0.5rem; /* Reduced margin */
    padding: 0.3rem 0; /* Reduced padding */
    transition: all 0.3s ease;
    display: block;
    width: 100%;
    border-bottom: 1px solid transparent;
    text-align: left; /* Left justify links */
}

.user-panel-link:hover {
    color: var(--secondary-color);
    /* border-bottom-color: var(--secondary-color); */
}

/* Desktop styles */
@media (min-width: 768px) {
    .user-panel {
        background-color: transparent;
        justify-content: flex-end;
        align-items: flex-start;
        pointer-events: none;
        
    }

    .user-panel-content {
        height: 100vh;
        border-radius: 12px 0 0 12px;
        transform: translateX(100%);
        animation: slideInRight 0.3s forwards;
        padding-top: 80px;
        width: 300px;
        max-width: none;
        pointer-events: auto;
        border-right: none;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        border-left: 1px solid var(--border-color);
    }

    @keyframes slideInRight {
        to {
            transform: translateX(0);
        }
    }
    /* Remove the body class that prevents scrolling on desktop */
    body.panel-open {
        overflow: auto;
    }
}

/* Mobile styles */
@media (max-width: 767px) {
    .user-panel-content {
        width: 95%;
        max-width: 95%;
        height: auto;
        max-height: 90vh;
        overflow-y: auto;
        border: 1px solid var(--border-color);
    }
}

.user-stats {
    width: 90%;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid var(--border-color); /* Slightly darker border for contrast */
    border-radius: 6px; /* Rounded corners for the stats box */
    /*background-color: var(--cardBackground-color); /* White background for contrast */
}

.user-stats p {
    font-size: 0.9rem;
    color: var(--text-color1);
    margin: 0.3rem 0;
    text-align: left;
}
@media (max-width: 768px) {
    body.panel-open {
        overflow: hidden;
    }
}

.theme-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
}

.theme-toggle span {
  margin-left: 0.5rem;
}
