/* Apply Inter font to all text */
body, button, input, select, textarea {
    font-family: 'Inter', sans-serif;
}

.submit-event-container {
    width: 100%;
    max-width: 1200px;
    min-height: 800px;

    @media (max-width: 768px) {
        min-height: 600px;
    }
    margin: 0 auto;
    padding: 30px;
    box-sizing: border-box;
    background-color: var(--cardBackground-color);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.submit-event-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 16px;
}

.form-group input,
.form-group textarea,
.form-group select,
.react-datepicker__input-container input {
    width: 100%;
    padding: 12px 12px 12px 40px;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
}

.form-group .icon {
    position: absolute;
    left: 12px;
    top: calc(50% + 10px); /* Adjust this value to center the icon */
    transform: translateY(-50%);
    color:var(--primary-color);
    z-index: 1;
    pointer-events: none;
}

/* Specific adjustment for the date picker icon */
.form-group .react-datepicker-wrapper + .icon {
    top: 50%;
}

/* Date picker styles */
.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

/* Submit button styles */
.submit-button {
    background-color:var(--primary-color);
    color: var(--text-color2);
    border: none;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #00353b;
}

.submit-button svg {
    margin-right: 8px;
}

h2.submit-event-header {
    font-size: 24px;
    font-weight: 600;
    color:var(--primary-color);
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

/* Checkbox styling */
.form-group.checkbox-group {
    display: flex;
    align-items: center;
}

.form-group.checkbox-group input[type="checkbox"] {
    margin-right: 10px;
    width: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
    .submit-event-container {
        padding: 20px;
    }
}

@media (min-width: 1200px) {
    .submit-event-container {
        max-width: 800px;
    }
}

/* Layout for larger screens */
@media (min-width: 992px) {
    .submit-event-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .form-group {
        margin-bottom: 0;
    }

    .form-group.event-details,
    .form-group:last-child,
    .submit-button,
    .form-group.checkbox-group {
        grid-column: 1 / -1;
    }
}

/* Ensure the header spans the full width */
.submit-event-header {
    grid-column: 1 / -1;
}

.form-group.event-details textarea {
    height: 150px; /* Increase the height of the event details textarea */
    resize: vertical; /* Allow vertical resizing */
}

/* New styles for checkbox and disabled submit button */
.form-group.checkbox-group.terms-checkbox {
    margin-bottom: 20px;
}

.form-group.checkbox-group.terms-checkbox label {
    font-size: 14px;
    line-height: 1.4;
    margin-left: 10px;
    font-weight: normal;
}

.submit-button.disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.submit-button.disabled:hover {
    background-color: #cccccc;
}

/* Add these new styles at the end of the file */

    .submit-event-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 20px;
    }
    
    .submit-event-container h2 {
        font-size: 24px;
        font-weight: 600;
        color:var(--primary-color);
        max-width: 80%;
    }
    
    .date-input-wrapper {
        position: relative;
        width: 100%;
    }
    
    .calendar-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--primary-color);
        z-index: 2;
        pointer-events: none;
    }
    
    .date-input {
        width: 100%;
        padding: 10px 10px 10px 35px;
        border: 1px solid var(--primary-color);
        border-radius: 6px;
        font-size: 14px;
        transition: border-color 0.3s ease;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    .date-input:focus {
        outline: none;
        border-color: #00353b;
    }
    
    .custom-calendar {
        font-family: 'Inter', sans-serif;
        border-radius: 8px;
        border: 1px solid var(--primary-color);
    }

    
    
    .custom-calendar .react-datepicker__header {
        /* background-color: #bfc4c5; */
        border-bottom: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding-top: 8px;
    }
    
    .custom-calendar .react-datepicker__current-month,
    .custom-calendar .react-datepicker__day-name {
        color: rgb(0, 0, 0);
        font-size: 14px; 
        text-align: center; /* Center the font */
    }
    
    .custom-calendar .react-datepicker__day--selected,
    .custom-calendar .react-datepicker__day--in-selecting-range,
    .custom-calendar .react-datepicker__day--in-range {
        background-color: var(--primary-color);
        color: var(--text-color2);
    }
    
    .custom-calendar .react-datepicker__day:hover {
        background-color: var(--highlight-color);
    }

/* Add this to your existing CSS */

.submit-button.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.fa-spin {
    animation: fa-spin 2s infinite linear;
}

@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

