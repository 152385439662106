.loading-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px); /* Adjust 60px to match your header height */
    width: 100%;
    background-color: var(--background-color);
    color: var(--text-color2);
    position: fixed;
    top: 45px; /* Should match your header height */
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.spinner {
    border: 4px solid var(--secondary-color);
    border-top: 4px solid var(--highlight-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
