.reset-password-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 300px;
  margin-bottom: calc(100vh - 500px - 0px);
}

.reset-password-heading {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
}

.reset-password-input-group {
  margin-bottom: 15px;
}

.reset-password-label {
  margin-bottom: 5px;
  color: #555;
  font-size: 14px;
}

.reset-password-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.reset-password-button {
  background-color: var(--primary-color);
  color: var(--text-color2);
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-password-message {
  margin-top: 15px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
  text-align: center;
  color: #333;
}

.reset-password-checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.reset-password-checkbox {
  margin-right: 10px;
}