/* Header.css */
.app-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    box-sizing: border-box;
    background-color: var(--primary-color);
    color: var(--text-color2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    height: 45px;
}

.app-header.mobile-hidden {
    transform: translateY(-100%);
}

.logo-link {
    display: inline-block;
}

.logo {
    width: 180px;
    height: auto;
    padding-top: 12.5px;
}

.icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon {
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.venue-filter-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.venue-filter-container label {
    margin-bottom: 5px;
}

select {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
    background-color:var(--cardBackground-color);
    color: var(--text-color1);
    font-size: 14px;
    text-align: center;
}

.header a {
    display: inline-block;
}

.logged-in-as {
    font-size: 1rem;
    color: var(--text-color3);
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    text-align: left; /* Left justify text */
}

/* Optional: Adjust z-index if necessary */
.app-header {
    /* ... existing styles ... */
    z-index: 1000; /* Ensure header is above other elements */
}

/* ... existing styles ... */

.icon-disabled {
    opacity: 0.5;
    color: var(--text-color3);  /* Use your theme's muted color variable */
}
