.event-card {
    display: flex;
    flex-direction: column;
    padding: 12px 10px;
    border: 1px solid var(--cardBackground-color);
    border-radius: 8px;
    background-color: var(--cardBackground-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 3px auto;
    cursor: pointer;
    color: var(--text-color1);
    /* transition: all 0.1s ease; */
    width: 100%;
    max-width: 400px;
    min-height: 40px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

.event-card:hover {
    
    @media (min-width: 768px) {
        transform: translateY(-3px);
        /* box-shadow: 0 4px 15px var(--tertiary-color); */
        background-color: var(--cardBackground-color);
        border-color: var(--tertiary-color);
    }
}

.event-card.expanded {
    border-color: var(--tertiary-color);
    background-color: var(--cardBackground-color);
    /* box-shadow: 0 4px 15px var(--tertiary-color); */
}

.expanded-content {
    position: relative;
    margin-top: 8px;
    width: 100%;
}

.event-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.event-date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 60px;
    margin-right: 0px;
    flex-shrink: 0;
    margin-top: 1px;
}

.event-date {
    flex-shrink: 0;
    margin-right: 16px;
    min-width: 80px;
    font-size: 0.9rem;
    color: var(--event-date-color);
}

.event-day {
    font-size: 0.8rem;
    color: var(--event-day-color);
    margin-top: 2px;
}

.event-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: 0px;
}

.event-name {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    color: var(--event-name-color);
}

.event-venue {
    font-size: 0.9rem;
    color: var(--event-venue-color);
    margin: 0;
}

.save-event-container {
    display: flex;
    align-items: center;
}

.event-details {
    margin-top: 8px;
}

.event-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 0px;
}

.save-event-star {
    margin-left: 10px;
    padding: 5px; /* Add some padding to increase the clickable area */
}

.expanded-content .save-event-toggle {
    margin-bottom: 10px;
}

@media (max-width: 600px) {

    .event-card {
        max-width: 100%; /* Allow cards to take full width of container */
        width: calc(100% - 20px); /* Subtract some width for visual padding */
        margin-left: auto;
        margin-right: auto;
    }

    .event-header {
        flex-direction: row; /* Change back to row */
        align-items: flex-start;
    }

    .event-date {
        margin-right: 10px; /* Add some space between date and info */
        margin-bottom: 0; /* Remove bottom margin */
        min-width: 60px; /* Reduce min-width to save space */
    }

    .event-info {
        padding-left: 0; /* Remove the left padding */
        flex: 1; /* Allow it to take remaining space */
    }

    .event-name {
        margin-top: 0; /* Remove negative margin */
    }

    .event-venue {
        margin-top: 2px; /* Consistent with non-mobile */
    }

    .event-date-container {
        margin-right: 10px;
        min-width: 60px;
    }



}

/* Remove all .event-button, .buy-tickets-button, .google-calendar-button, and .ical-button styles */

.calendar-buttons-container {
    display: flex;
    gap: 8px;
    flex: 1;
    width: 100%;
}

.calendar-buttons-container .card-button {
    flex: 1;
    padding: 10px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-buttons-container .card-button svg {
    margin-right: 4px;
    
}

.share-button-container {
    flex: 1;
    margin: 0;
}

.share-button {
    width: 100%;
    padding: 10px 4px;
    background-color: var(--secondary-color);
    color: var(--text-color2);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-button:hover {
    background-color: var(--highlight-color);
}

.share-button svg {
    margin-right: 4px;
}

/* Remove any conflicting styles */
.share-icon-container {
    /* display: none; */
}

/* Add this new container */
.bottom-button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    gap: 4px;
    
}

.icon-button {
    background: none;
    border: none;
    color: var(--text-color1);
    cursor: pointer;
    padding: 0px 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
}

.icon-button:hover {
    color: var(--highlight-color);
}

/* Add these new styles */
.event-image-container {
    width: 100%;
    margin-top: 12px;
    border-radius: 4px;
    overflow: hidden;
}

.event-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

.event-image-link {
    display: block;
    width: 100%;
    cursor: pointer;
}

.event-image-link:hover {
    opacity: 0.9;
}
